<template>
  <v-container
    fluid
    tag="section"
  >
    <validation-observer v-slot="{ valid }">
      <material-wizard
        title="Nova Carga de CSV"
        subtitle="Faça upload do arquivo CSV e informe os parâmetros de criação da tabela destino"
        v-model="tab"
        :available-steps="availableSteps"
        :tabs="tabs"
        class="mx-auto"
        @click:next="next(valid)"
        @click:prev="tab--"
        :loading="loading"
        :disabledBtnFinalizar="disabledBtnFinalizar"
      >
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <first-tab-content
              @file:uploaded="handleFileUploaded"
              @encoding:selected="handleEncodingSelected"
              @delimiter:changed="handleDelimiterChanged"
            />
          </form>
        </v-tab-item>
        <v-tab-item class="pb-1">
          <form @submit.prevent="next(valid)">
            <second-tab-content
              :file="carga.arquivo"
              :delimiter="carga.delimitador"
              @import:updated="handleTabContentUpdated"
            />
          </form>
        </v-tab-item>
      </material-wizard>
    </validation-observer>
  </v-container>
</template>

<script>
import CargasCsvService from '@/services/CargasCsvService';
import MaterialWizard from '@/components/base/MaterialWizard.vue';
import FirstTabContent from '@/components/dados/cargas-csv/tabs/FirstTabContent.vue';
import SecondTabContent from '@/components/dados/cargas-csv/tabs/SecondTabContent.vue';
import routes from '@/store/modules/routes';

export default {
  components: {
    MaterialWizard,
    FirstTabContent,
    SecondTabContent
  },
  data: () => ({
    tab: 0,
    tabs: ['Upload de arquivo', 'Tabela Destino'],
    loading: false,
    carga: {
      schemaDestino: null,
      nomeTabelaDestino: null,
      colunasTabelaDestino: [],
      arquivo: null,
      delimitador: ';',
      encoding: 'UTF-8',
      companyId: null
    }
  }),
  computed: {
    availableSteps() {
      let steps = [];
      if (this.tab1IsValid) {
        steps.push(0);
        steps.push(1);
      }
      if (this.tab2IsValid) {
        steps.push(2);
      }
      return steps;
    },
    tab1IsValid() {
      const { arquivo, delimitador, encoding } = this.carga;
      return (
        arquivo !== null &&
        delimitador !== null &&
        delimitador !== '' &&
        encoding !== null
      );
    },
    tab2IsValid() {
      const {
        schemaDestino,
        nomeTabelaDestino,
        colunasTabelaDestino,
        companyId
      } = this.carga;
      return (
        this.tab1IsValid &&
        schemaDestino !== null &&
        nomeTabelaDestino !== null &&
        colunasTabelaDestino.length > 0 &&
        companyId > 0
      );
    },
    disabledBtnFinalizar() {
      if (this.tab === 0) return !this.tab1IsValid;
      if (this.tab === 1) return !this.tab2IsValid;
      return true;
    }
  },
  methods: {
    async next(valid) {
      if (!valid) return;

      if (this.tab === 0 && !this.tab1IsValid) {
        return;
      }

      if (this.tab === 1) {
        if (!this.tab2IsValid) {
          return;
        }

        const sucesso = await this.cadastrarNovaCarga();
        if (!sucesso) {
          return;
        }

        this.$router.push({
          name: routes.state.appRoutes['CARGAS_CSV']
        });
      } else {
        this.tab++;
      }
    },
    handleTabContentUpdated(event) {
      this.carga = Object.assign(this.carga, event);
    },
    handleFileUploaded(file) {
      this.carga.arquivo = file;
    },
    handleEncodingSelected(event) {
      this.carga.encoding = event;
    },
    handleDelimiterChanged(event) {
      this.carga.delimitador = event;
    },
    async cadastrarNovaCarga() {
      let sucesso = false;
      this.loading = true;

      try {
        const {
          schemaDestino,
          nomeTabelaDestino,
          colunasTabelaDestino,
          arquivo,
          delimitador,
          encoding,
          companyId
        } = this.carga;

        const carga = {
          schema_destino: schemaDestino,
          nome_tabela_destino: nomeTabelaDestino,
          colunas_tabela_destino: colunasTabelaDestino,
          arquivo_id: arquivo.id,
          delimitador,
          encoding,
          company_id: companyId
        };
        sucesso = await CargasCsvService.save(carga);
        this.$toast.success('Carga de CSV cadastrada com sucesso.', '', {
          position: 'topRight'
        });
      } catch (e) {
        this.$toast.error('Ocorreu um erro ao cadastrar a carga de CSV.', '', {
          position: 'topRight'
        });
        console.log(e);
      } finally {
        this.loading = false;
      }

      return sucesso;
    }
  }
};
</script>
