<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Informe os parâmetros para a criação da tabela de destino
    </div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <select-company
          :type="'outlined'"
          :selectedCompany="userLoggedCompany"
          @companySelected="companySelected"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="companyId"
    >
      <v-col cols="12">
        <select-schema
          :type="'outlined'"
          :companyId="companyId"
          @schemaSelected="schemaSelected"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="file && delimiter && schemaDestino && dadosTabelaDestino"
    >
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
            >
              <v-text-field
                label="Nome da Tabela Destino"
                outlined
                :error-messages="errors"
                v-model="nomeTabelaDestino"
                maxlength="100"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Nome da Coluna</th>
                    <th class="text-left">Tipo da Coluna</th>
                    <th class="text-left">Dado de Exemplo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      cabecalho, i
                    ) in dadosTabelaDestino.cabecalho_arquivo"
                    :key="i"
                  >
                    <td>
                      <validation-provider
                        v-slot="{ errors }"
                        rules="required"
                      >
                        <v-text-field
                          :label="cabecalho"
                          outlined
                          :error-messages="errors"
                          v-model="colunasTabelaDestino[i].nome_coluna"
                          maxlength="100"
                        />
                      </validation-provider>
                    </td>
                    <td>
                      <v-select
                        v-model="colunasTabelaDestino[i].tipo_coluna"
                        label="Tipo da Coluna"
                        :items="[
                          'varchar',
                          'text',
                          'integer',
                          'numeric',
                          'date',
                          'datetime'
                        ]"
                        outlined
                        class="mb-0 pb-0"
                        no-data-text="Nenhum tipo válido encontrado"
                      />
                    </td>
                    <td>{{ dadosTabelaDestino.primeira_linha[i] }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CargasCsvService from '@/services/CargasCsvService';
import SelectCompany from '@/components/general/SelectCompany.vue';
import SelectSchema from '@/components/general/SelectSchema.vue';

export default {
  name: 'SecondTabContent',
  components: {
    SelectCompany,
    SelectSchema
  },
  props: {
    file: {
      type: Object,
      required: true
    },
    delimiter: {
      type: String,
      default: ';'
    }
  },
  data() {
    return {
      dadosTabelaDestino: null,
      schemaDestino: null,
      nomeTabelaDestino: null,
      colunasTabelaDestino: [],
      companyId: null
    };
  },
  mounted() {
    this.getDadosTabelaDestino();
  },
  computed: {
    userLoggedCompany() {
      return this.$store.getters.getSelectedCompany;
    }
  },
  methods: {
    companySelected(company) {
      this.companyId = company.id;
    },
    schemaSelected(schemaSelected) {
      this.schemaDestino = schemaSelected;
    },
    emitEventImportUpdated() {
      this.$emit('import:updated', {
        schemaDestino: this.schemaDestino,
        nomeTabelaDestino: this.nomeTabelaDestino,
        colunasTabelaDestino: this.colunasTabelaDestino,
        companyId: this.companyId
      });
    },
    getDadosTabelaDestino() {
      if (!this.file || !this.delimiter) return;
      CargasCsvService.getDadosTabelaDestino(this.file.id, this.delimiter).then(
        ({ data }) => (this.dadosTabelaDestino = data)
      );
    }
  },
  watch: {
    schemaDestino() {
      this.emitEventImportUpdated();
    },
    nomeTabelaDestino() {
      this.emitEventImportUpdated();
    },
    colunasTabelaDestino() {
      this.emitEventImportUpdated();
    },
    companyId() {
      this.emitEventImportUpdated();
    },
    file() {
      this.getDadosTabelaDestino();
    },
    delimiter() {
      this.getDadosTabelaDestino();
    },
    dadosTabelaDestino() {
      this.nomeTabelaDestino = this.dadosTabelaDestino.nome_tabela_destino;
      this.colunasTabelaDestino =
        this.dadosTabelaDestino.colunas_tabela_destino.map((coluna) => ({
          nome_coluna: coluna,
          tipo_coluna: 'varchar'
        }));
    }
  }
};
</script>
