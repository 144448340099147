<template>
  <div>
    <div class="text-center display-1 font-weight-normal mb-6">
      Por favor, envie o CSV a ser carregado no banco de dados
    </div>
    <v-row class="mx-auto">
      <v-col cols="12">
        <upload-files
          :allowedFiles="allowedFiles"
          @file:uploaded="handleFileUploaded"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="lastUploadedFile"
    >
      <v-col cols="12">
        <v-text-field
          label="Arquivo enviado"
          outlined
          readonly
          v-model="lastUploadedFile.arquivo"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="lastUploadedFile"
    >
      <v-col cols="12">
        <select-encoding
          label="Codificação do arquivo"
          type="outlined"
          :selected="encoding"
          @encoding:selected="encodingSelected"
        />
      </v-col>
    </v-row>
    <v-row
      class="mx-auto"
      v-if="lastUploadedFile"
    >
      <v-col cols="12">
        <validation-provider
          v-slot="{ errors }"
          rules="required"
        >
          <v-text-field
            label="Delimitador do arquivo"
            outlined
            :error-messages="errors"
            v-model="delimiter"
            maxlength="1"
          />
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CargasCsvService from '@/services/CargasCsvService';
import SelectEncoding from '@/components/general/SelectEncoding.vue';
import UploadFiles from '@/components/general/UploadFiles/Index.vue';

export default {
  name: 'FirstTabContent',
  components: {
    SelectEncoding,
    UploadFiles
  },
  data() {
    return {
      encoding: 'UTF-8',
      delimiter: ';',
      allowedFiles: [],
      uploadedFiles: []
    };
  },
  mounted() {
    this.getAllowedFiles();
  },
  watch: {
    encoding() {
      this.$emit('encoding:selected', this.encoding);
    },
    delimiter() {
      this.$emit('delimiter:changed', this.delimiter);
    },
    uploadedFiles() {
      this.$emit('file:uploaded', this.lastUploadedFile);
    }
  },
  computed: {
    lastUploadedFile() {
      return this.uploadedFiles[this.uploadedFiles.length - 1];
    }
  },
  methods: {
    getAllowedFiles() {
      CargasCsvService.getAllowedFiles()
        .then((response) => (this.allowedFiles = response.data))
        .catch((error) => console.log('Error: ' + error));
    },
    handleFileUploaded(event) {
      const { files } = event;
      files.forEach((file) => this.uploadedFiles.push(file));
    },
    encodingSelected(event) {
      this.encoding = event;
    }
  }
};
</script>
