<template>
  <v-select
    v-model="schema"
    name="schema"
    label="Schema Destino"
    :items="schemas"
    :outlined="type === 'outlined'"
    class="mb-0 pb-0"
    no-data-text="Nenhum schema encontrado para a empresa selecionada"
    :loading="loading"
    @change="schemaSelected"
  />
</template>

<script>
import CompanyService from '@/services/CompanyService';

export default {
  name: 'SelectSchema',
  props: {
    type: {
      type: String,
      default: 'solo'
    },
    companyId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      schema: '',
      schemas: [],
      loading: true
    };
  },
  mounted() {
    this.getSchemasByCompany();
  },
  methods: {
    getSchemasByCompany() {
      if (!this.companyId) return;
      CompanyService.getSchemas(this.companyId)
        .then(({ data }) => this.setSchemas(data))
        .finally(() => (this.loading = false));
    },
    setSchemas(schemas) {
      this.schemas = schemas;
      this.schemaSelected(schemas[0]);
    },
    schemaSelected(schemaSelected) {
      this.schema = schemaSelected;
      this.$emit('schemaSelected', schemaSelected);
    }
  },
  watch: {
    companyId() {
      this.getSchemasByCompany();
    }
  }
};
</script>
