var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-center display-1 font-weight-normal mb-6"},[_vm._v(" Informe os parâmetros para a criação da tabela de destino ")]),_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('select-company',{attrs:{"type":'outlined',"selectedCompany":_vm.userLoggedCompany},on:{"companySelected":_vm.companySelected}})],1)],1),(_vm.companyId)?_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('select-schema',{attrs:{"type":'outlined',"companyId":_vm.companyId},on:{"schemaSelected":_vm.schemaSelected}})],1)],1):_vm._e(),(_vm.file && _vm.delimiter && _vm.schemaDestino && _vm.dadosTabelaDestino)?_c('v-row',{staticClass:"mx-auto"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome da Tabela Destino","outlined":"","error-messages":errors,"maxlength":"100"},model:{value:(_vm.nomeTabelaDestino),callback:function ($$v) {_vm.nomeTabelaDestino=$$v},expression:"nomeTabelaDestino"}})]}}],null,false,1705053829)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Nome da Coluna")]),_c('th',{staticClass:"text-left"},[_vm._v("Tipo da Coluna")]),_c('th',{staticClass:"text-left"},[_vm._v("Dado de Exemplo")])])]),_c('tbody',_vm._l((_vm.dadosTabelaDestino.cabecalho_arquivo),function(cabecalho,i){return _c('tr',{key:i},[_c('td',[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":cabecalho,"outlined":"","error-messages":errors,"maxlength":"100"},model:{value:(_vm.colunasTabelaDestino[i].nome_coluna),callback:function ($$v) {_vm.$set(_vm.colunasTabelaDestino[i], "nome_coluna", $$v)},expression:"colunasTabelaDestino[i].nome_coluna"}})]}}],null,true)})],1),_c('td',[_c('v-select',{staticClass:"mb-0 pb-0",attrs:{"label":"Tipo da Coluna","items":[
                        'varchar',
                        'text',
                        'integer',
                        'numeric',
                        'date',
                        'datetime'
                      ],"outlined":"","no-data-text":"Nenhum tipo válido encontrado"},model:{value:(_vm.colunasTabelaDestino[i].tipo_coluna),callback:function ($$v) {_vm.$set(_vm.colunasTabelaDestino[i], "tipo_coluna", $$v)},expression:"colunasTabelaDestino[i].tipo_coluna"}})],1),_c('td',[_vm._v(_vm._s(_vm.dadosTabelaDestino.primeira_linha[i]))])])}),0)]},proxy:true}],null,false,2253033146)})],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }