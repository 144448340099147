import APIService from './APIService';

export default {
  getCargas() {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .get('cargas-arquivos-csv/get-cargas')
        .then((response) => {
          const { data: cargas } = response.data;
          resolve(cargas);
        })
        .catch((error) => reject(error));
    });
  },
  save(data) {
    return new Promise((resolve, reject) => {
      APIService.apiCall()
        .post('cargas-arquivos-csv/salvar', JSON.stringify(data))
        .then(() => resolve(true))
        .catch(() => reject(false));
    });
  },
  getAllowedFiles() {
    return APIService.apiCall().get('cargas-arquivos-csv/arquivos-permitidos');
  },
  getDadosTabelaDestino(arquivoId, delimitador) {
    return APIService.apiCall().get(`cargas-arquivos-csv/get-dados-tabela-destino/${arquivoId}?delimitador=${delimitador}`);
  },
  baixarZip(id, config = {}) {
    return APIService.apiCall().get(
      `/cargas-arquivos-csv/download-arquivo-zip-logs/${id}`,
      { responseType: 'blob', ...config }
    );
  },
};
